
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { mapActions, mapGetters } from "vuex";
import MessageBox from "@/components/MessageBox.vue";

// Services
import ScanService from "@/services/ScanService";
import LocationService from "@/services/LocationService";
import ActionService from "@/services/ShipService";
import PartsService from "@/services/PartsService";
import LotService from "@/services/LotInfoService";
import InventoryService from "@/services/inventory";

// Utlities
import Utils from "@/utility/utils";
const scanService = new ScanService(process.env.VUE_APP_ABSTRACTION_API);
const locationService = new LocationService(
  process.env.VUE_APP_ABSTRACTION_API
);
const inventoryService = new InventoryService(
  process.env.VUE_APP_ABSTRACTION_API
);
const lotService = new LotService(process.env.VUE_APP_ABSTRACTION_API);
const partsService = new PartsService();
const shipService = new ActionService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "Details",
  components: {
    InputText,
    Button,
    MessageBox,
  },
  data() {
    return {
      packShipPayload: {
        to_loc: "",
        ship_id: "",
        from_loc: "",
        from_bin: "",
        lot: "",
        part: "",
        quantity: "",
        update_ship: false,
        skip_pull_pack: false,
        allow_new_quantity: false,
        ship_short: false,
        user_id: "",
      },
      ship_pick_li: {} as any,
      lot_control: false,
      from_inv_bin_control: false,
      from_inv_neg_ok: false,
      from_inv_phys: false,
      from_inv_type: "",
      fractions: false,
      balance: 0.0,
      loading: false,
      direct_flag: false,
      showIsShipComplete: false,
      showOpenItemList: false,
      showCompleteShort: false,
      showConfirmPassword: false,
      showSkipPullPack: false,
      showErrorDialog: false,
      errorMessage: "",
      focusRefName: "",
      loadingExitButton: false,
      openItemListMessage: "",
      showConfirmQuantity: false,
      messageConfirmQuantity: "",
      update_ship: false,
      rowClass:
        "p-field p-col col-12 m-0 p-0 pb-1 flex justify-content-center r-mono",
    };
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getUser: "session/getUser",
    }),
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    clearAll() {
      this.packShipPayload.to_loc = "";
      this.packShipPayload.ship_id = "";
      this.packShipPayload.from_loc = "";
      this.packShipPayload.from_bin = "";
      this.packShipPayload.lot = "";
      this.packShipPayload.part = "";
      this.packShipPayload.quantity = "";
      this.packShipPayload.update_ship = false;
      this.packShipPayload.allow_new_quantity = false;
      this.packShipPayload.ship_short = false;
      this.packShipPayload.skip_pull_pack = false;
      this.packShipPayload.user_id = this.getUser.user_id;

      this.ship_pick_li = {};
      this.lot_control = false;
      this.from_inv_bin_control = false;
      this.from_inv_neg_ok = false;
      this.from_inv_phys = false;
      this.from_inv_type = "";
      (this.fractions = false), (this.balance = 0.0);
    },
    clearSubmit() {
      this.packShipPayload.lot = "";
      this.packShipPayload.part = "";
      this.packShipPayload.quantity = "";
      this.packShipPayload.update_ship = false;

      this.ship_pick_li = {};
      this.lot_control = false;
      this.from_inv_bin_control = false;
      this.from_inv_neg_ok = false;
      this.from_inv_phys = false;
      this.from_inv_type = "";
      (this.fractions = false), (this.balance = 0.0);
    },
    focusInput(refName: string) {
      if (refName) {
        (this.$refs[refName] as any).$el.focus();
        (this.$refs[refName] as any).$el.select();
      }
    },
    start() {
      var err = null;
      if (!this.packShipPayload.to_loc) {
        err = "To location required.";
        this.focusInput("to_loc");
      }

      if (!this.packShipPayload.ship_id) {
        err = "Ship ID required.";
        this.focusInput("ship_id");
      }

      if (!this.packShipPayload.from_loc) {
        err = "From location required.";
        this.focusInput("from_loc");
      }

      if (err) {
        this.handleError(err);
        return;
      }

      if (err) {
        this.handleError(err);
        return;
      }

      this.loading = true;
      scanService
        .postPackShipment(this.packShipPayload)
        .then((resp: any) => {
          this.handleMessage(resp?.message || "Success");
          this.clearSubmit();
          this.focusInput("part");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleToLocation() {
      if (this.packShipPayload.to_loc) {
        locationService
          .getLocationById(
            this.getClient,
            this.packShipPayload.to_loc,
            "invloc_id bin_control type"
          )
          .then((resp: any) => {
            if (resp.invloc_items && resp.invloc_items[0]) {
              const location = resp.invloc_items[0];
              this.packShipPayload.to_loc = location.invloc_id;

              if (location.bin_control === "Y") {
                throw new Error("Bin controlled location not allowed");
              }

              if (location.type === "WO") {
                throw new Error("WIP location not allowed");
              }

              if (this.packShipPayload.ship_id) {
                shipService
                  .getShipById(
                    this.getClient,
                    this.packShipPayload.ship_id,
                    "pull_loc"
                  )
                  .then((resp: any) => {
                    if (resp && resp[0]) {
                      const ship = resp[0];
                      if (ship.pull_loc != this.packShipPayload.to_loc) {
                        this.packShipPayload.to_loc = ship.pull_loc;
                        this.handleMessage(
                          "Shipment pull to location has already been set to " +
                            this.packShipPayload.to_loc +
                            "."
                        );
                      }
                    }
                  });
              }

              this.focusInput("ship_id");
            } else {
              throw new Error(
                this.packShipPayload.to_loc +
                  " is not a valid inventory location"
              );
            }
          })
          .catch((err) => {
            this.handleErrorAndFocus(
              err.toString() || "Error with To Location.",
              "to_loc"
            );
          });
      } else {
        this.handleError("An inventory location must be specified");
        this.focusInput("to_loc");
      }
    },
    handleShipId() {
      if (this.packShipPayload.ship_id) {
        const fieldNames =
          "ship_id via status pull_date pull_loc pack_pull_date pick_li pick_part pick_ship_qty pick_location pick_qty_pulled pack_scan_qty";
        shipService
          .getShipById(this.getClient, this.packShipPayload.ship_id, fieldNames)
          .then((resp: any) => {
            if (resp && resp.ship_items && resp.ship_items.length > 0) {
              const ship_item = resp.ship_items[0];
              this.packShipPayload.ship_id = ship_item.ship_id;
              this.packShipPayload.skip_pull_pack = false;

              if (
                ship_item.via &&
                (ship_item.via as string).includes("WILL CALL")
              ) {
                inventoryService
                  .getInventoryById(
                    this.getClient,
                    "WILCAL",
                    "inactive type bin_control",
                    ""
                  )
                  .then((resp: any) => {
                    if (
                      resp.inactive != "Y" &&
                      resp.type != "WO" &&
                      resp.bin_control != "Y"
                    ) {
                      this.packShipPayload.from_loc = "WILCAL";
                    }
                  });
              }

              if (ship_item.status != "N") {
                throw new Error("Shipment has already been shipped.");
              }

              if (ship_item.pull_date) {
                throw new Error("Shipment has already been pulled.");
              }

              if (!ship_item.pack_pull_date) {
                this.showSkipPullPack = true;
              } else {
                this.ship_pick_li = ship_item.pick_li_items;

                if(!this.packShipPayload.from_loc) {
                  this.packShipPayload.from_loc = "PACK";
                  this.handleFromLocation();
                } else {
                  this.focusInput("from_loc");
                }
              }
            } else {
              throw new Error("Shipment not found.");
            }
          })
          .catch((err) => {
            this.packShipPayload.ship_id = "";
            this.handleErrorAndFocus(
              err.toString() || "Error with Ship ID.",
              "ship_id"
            );
          });
      }
    },
    handleFromLocation() {
      if (this.packShipPayload.from_loc) {
        const [from_loc, bin] = Utils.splitStringInTwo(
          this.packShipPayload.from_loc,
          "-"
        );
        this.packShipPayload.from_loc = from_loc;
        this.packShipPayload.from_bin = bin;

        locationService
          .getLocationById(
            this.getClient,
            this.packShipPayload.from_loc,
            "invloc_id bin_control type neg_ok phys"
          )
          .then((resp: any) => {
            if (resp.invloc_items && resp.invloc_items[0]) {
              const location = resp.invloc_items[0];
              this.packShipPayload.from_loc = location.invloc_id;
              this.from_inv_bin_control = location.bin_control === "Y";
              this.from_inv_neg_ok = location.neg_ok === "Y";
              this.from_inv_phys = location.phys === "Y";
              this.from_inv_type = location.type;

              if (this.from_inv_type === "WO") {
                throw new Error("WIP location not allowed");
              }

              if (!this.from_inv_bin_control) {
                this.packShipPayload.from_bin = "";
                this.focusInput("part");
                return;
              }

              if (this.from_inv_bin_control && bin) {
                this.focusInput("part");
                return;
              }

              this.focusInput("from_bin");
            } else {
              throw new Error(from_loc + " is not a valid inventory location");
            }
          })
          .catch((err) => {
            this.packShipPayload.from_loc = "";
            this.packShipPayload.from_bin = "";
            this.handleErrorAndFocus(
              err.toString() || "Error with From Location.",
              "from_loc"
            );
          });
      }
    },
    handleFromBin() {
      if (!this.from_inv_bin_control) {
        if (!this.packShipPayload.from_bin) {
          this.handleError(
            this.packShipPayload.from_loc + " is not a bin controlled location."
          );
        }
        return;
      }

      if (!this.packShipPayload.from_bin) {
        this.handleErrorAndFocus("Bin number required.", "from_bin");
      } else {
        this.focusInput("part");
      }
    },
    handlePart() {
      if (this.packShipPayload.part) {
        partsService
          .getPartByID({
            client: this.getClient,
            id: this.packShipPayload.part,
            fieldnames: "part_no lot_control fractions",
          })
          .then((resp: any) => {
            this.packShipPayload.part = resp.part_no;
            this.lot_control = resp.lot_control === "Y";
            this.fractions = resp.fractions === "Y";
            if (!this.lot_control) {
              this.focusInput("quantity");
            } else {
              this.packShipPayload.lot = "";
              this.focusInput("lot");
            }

            const pick_li = this.ship_pick_li.find(
              (pick_li: any) => pick_li.pick_part === this.packShipPayload.part
            );

            this.calculateBalance(pick_li);
          })
          .catch((err) => {
            console.log(err);
            const message =
              err.response.data.message ||
              this.packShipPayload.part + " is not a valid part number.";
            this.handleErrorAndFocus(message, "part");
          });
      }
    },
    handleLot() {
      if (this.lot_control) {
        if (this.packShipPayload.lot) {
          lotService
            .getLot(this.packShipPayload.lot, "lot_number part_number")
            .then((resp: any) => {
              this.packShipPayload.lot = resp.lot_number;
              let found = false;
              if (this.packShipPayload.part != resp.part_number) {
                throw new Error(
                  "Lot number " +
                    this.packShipPayload.part +
                    " is for part " +
                    resp.part_number
                );
              }

              const pick_li = this.ship_pick_li.find(
                (pick_li: any) =>
                  pick_li.pick_part === this.packShipPayload.part
              );

              if (!pick_li) {
                if (!this.balance) {
                  throw new Error(
                    "Part number and location not found on shipment."
                  );
                } else {
                  if (this.packShipPayload.from_loc === "PACK") {
                    throw new Error(
                      "No Balance left to scan from PACK for this part."
                    );
                  } else {
                    throw new Error("No Balance left to scan for this part.");
                  }
                }
              }
              this.calculateBalance(pick_li);
            })
            .catch((err) => {
              const message =
                err || this.packShipPayload.lot + " is not a valid Lot.";
              this.handleError(message);
            });
        } else {
          this.handleErrorAndFocus("Lot number required.", "lot");
        }
      } else {
        const message = this.packShipPayload.part + " is not lot controlled";
        this.handleError(message);
      }
    },
    handleQuantity() {
      if (!this.fractions) {
        if (!Number.isInteger(this.packShipPayload.quantity)) {
          this.handleError("Fractions are not permitted");
          return;
        }
      }

      if (+this.packShipPayload.quantity > 0) {
        if (+this.packShipPayload.quantity > this.balance) {
          this.handleError("Quantity exceeds balance to pull for this part.");
        }

        if (this.from_inv_bin_control && !this.from_inv_neg_ok) {
          inventoryService
            .getInventoryById(
              this.getClient,
              this.packShipPayload.part,
              "part_no invloc bin bin_qty",
              ""
            )
            .then((resp: any) => {
              const invloc = resp.inv_items[0].invloc_items.find(
                (item: any) => item.invloc === this.packShipPayload.from_loc
              );

              if (invloc) {
                const bin = invloc.find(
                  (item: any) => item.bin === this.packShipPayload.from_bin
                );
                if (bin) {
                  if (
                    +this.packShipPayload.quantity > parseFloat(bin.bin_qty)
                  ) {
                    this.handleError("Insufficient quantity in bin.");
                  }
                } else {
                  this.handleError(
                    "Bin not found for location " +
                      this.packShipPayload.from_loc
                  );
                }

                if (this.from_inv_phys) {
                  if (this.lot_control) {
                    console.log("need to add lot control check here");
                  } else {
                    let on_hand = 0;
                    if (invloc.on_hand) {
                      on_hand = parseFloat(invloc.on_hand) || 0.0;
                    }

                    if (+this.packShipPayload.quantity > 0) {
                      if (+this.packShipPayload.quantity > on_hand) {
                        if (!this.from_inv_neg_ok) {
                          this.handleError(
                            "The on-hand balance is '" + on_hand + "."
                          );
                        } else {
                          const new_quantity =
                            on_hand - +this.packShipPayload.quantity;
                          this.messageConfirmQuantity = `Balance will become ${new_quantity}. Okay?`;
                          this.showConfirmQuantity = true;
                        }
                      }
                    }
                  }
                }
              } else {
                this.handleError("Inventory location not found.");
              }
            })
            .catch((err) => {
              this.handleError("No inventory record found.");
            });
        }
      } else if (+this.packShipPayload.quantity < 0) {
        if (!this.from_inv_neg_ok) {
          inventoryService.getInventoryById(
            this.getClient,
            this.packShipPayload.part,
            "part_no invloc bin bin_qty",
            ""
          )
            .then((resp: any) => {
              const invloc = resp.inv_items[0].invloc_items.find(
                (item: any) => item.invloc === this.packShipPayload.to_loc
              );

              if (invloc) {
                const bin = invloc.find(
                  (item: any) => item.bin === this.packShipPayload.from_bin
                );
                if (bin) {
                  if (
                    +this.packShipPayload.quantity > parseFloat(bin.bin_qty)
                  ) {
                    this.handleError(
                      "Insufficient quantity in " +
                        this.packShipPayload.to_loc +
                        " to reverse."
                    );
                  }
                } else {
                  this.handleError(
                    "Insufficient quantity in " +
                      this.packShipPayload.to_loc +
                      " to reverse."
                  );
                }
              } else {
                this.handleError(
                  "Insufficient quantity in " +
                    this.packShipPayload.to_loc +
                    " to reverse."
                );
              }
            });
        }
      }
    },
    clickedOpenItemList(response: boolean) {
      if (response) {
        this.focusInput("part");
      } else {
        this.showCompleteShort = true;
      }
      this.showOpenItemList = false;
    },
    calculateBalance(pick_li: any) {

      if(this.packShipPayload.from_loc === "PACK") {
        this.balance = 0;

        pick_li.forEach((item: any) => {
          this.balance += parseFloat(item.pack_scan_qty) || 0.0;
        });
      } else {

        const loc_options = ["NFDF", "FG1", "FG", "VIASTG"];

        if (
          pick_li.pick_location === this.packShipPayload.from_loc ||
          loc_options.includes(this.packShipPayload.from_loc)
        ) {
          const req_qty = parseFloat(pick_li.pick_ship_qty) || 0.0;
          let scan_qty = 0;
          if(pick_li.pick_qty_pulled_items) {
            pick_li.pick_qty_pulled_items.forEach((item: any) => {
              scan_qty += parseFloat(item.pick_qty_pulled);
            });
          }

          let pack_qty = 0;
          if(pick_li.pack_qty_pulled_items) {
            pick_li.pack_qty_pulled_items.forEach((item: any) => {
              pack_qty += parseFloat(item.pack_scan_qty);
            });
          }

          if (req_qty > scan_qty + pack_qty) {
            this.balance = req_qty - (scan_qty + pack_qty);
          } else {
            this.balance = 0;
          }
        }

      }
    },
    clickedConfirmPickDone(response: boolean) {
      if (response) {
        this.packShipPayload.ship_short = true;
        this.update_ship = true;
      }
      this.showCompleteShort = false;
    },
    clickedSkipPullPackDialog(response: boolean) {
      if (response) {
        this.packShipPayload.skip_pull_pack = true;
        this.focusInput("from_loc");
      } else {
        this.packShipPayload.skip_pull_pack = false;
        this.handleError("Please complete the pull/pack step.");
      }
      this.showSkipPullPack = false;
    },
    clickedIsShipComplete(response: boolean) {
      if (response) {
        const fieldNames =
          "ship_id status pull_date pull_loc pack_pull_date pick_li pick_part pick_ship_qty pick_location pick_qty_pulled pack_scan_qty";
        shipService
          .getShipById(this.getClient, this.packShipPayload.ship_id, fieldNames)
          .then((resp: any) => {
            if (resp && resp.ship_items && resp.ship_items.length > 0) {
              const ship_item: any = resp.ship_items[0];
              this.packShipPayload.ship_id = ship_item.ship_id;
              const open_li_list: any[] = [];

              ship_item.pick_li_items.forEach((pick_li: any) => {
                const req_qty = parseFloat(pick_li.pick_ship_qty) || 0.0;
                let scan_qty = 0;
                pick_li.pick_qty_pulled_items.forEach((item: any) => {
                  scan_qty += parseFloat(item.pick_qty_pulled);
                });

                if (req_qty > scan_qty) {
                  open_li_list.push(
                    pick_li.pick_li + " - " + pick_li.pick_part
                  );
                }
              });

              if (open_li_list.length > 0) {
                this.showOpenItemList = true;
                this.openItemListMessage =
                  "The following line items have not been scanned:\r\n";
                this.openItemListMessage += open_li_list.join("\r\n");
                this.openItemListMessage +=
                  "\r\n\r\nDo you want to continue scanning?";
              } else {
                this.packShipPayload.ship_short = false;
                this.update_ship = true;
              }
            } else {
              throw new Error("No ship items found.");
            }
          })
          .catch((err) => {
            this.handleError(err || "Error with Ship ID.");
          });
      }
      this.showIsShipComplete = false;
    },
    clickedConfirmQuantity(response: boolean) {
      this.packShipPayload.allow_new_quantity = response;
      this.showConfirmQuantity = false;
    },
    handleExitTime() {
      if (!this.packShipPayload.ship_id) {
        this.$router.push("/scan");
        return;
      } else {
        this.showIsShipComplete = true;
      }
    },
    updateShip() {
      this.packShipPayload.update_ship = true;
      scanService
        .postPackShipment(this.packShipPayload)
        .then((resp: any) => {
          this.handleMessage(
            resp?.message || "Successfully updated shipment."
          );
          this.$router.push("/scan");
        })
        .finally(() => {
          this.loading = false;
          this.packShipPayload.update_ship = false;
        });
    },
    handleError(message: string) {
      const notification = {
        dialog: true,
        type: "error",
        message,
      };
      this.addNotification(notification, { root: true });
    },
    handleMessage(message: string) {
      const notification = {
        dialog: true,
        type: "success",
        message,
      };
      this.addNotification(notification, { root: true });
    },
    handleErrorAndFocus(message: string, ref: string) {
      this.errorMessage = message;
      this.showErrorDialog = true;
      this.focusRefName = ref;
    },
    clickConfirmErrorDialog() {
      this.showErrorDialog = false;
      if (this.focusRefName) {
        this.focusInput(this.focusRefName);
      }
      this.focusRefName = "";
    },
    handleSuccessDialog(message: string) {
      const notification = {
        dialog: true,
        type: "success",
        message,
      };
      this.addNotification(notification, { root: true });
    },
  },
  mounted() {
    this.clearAll();
    this.packShipPayload.to_loc = "SHIP";
    this.packShipPayload.skip_pull_pack = false;
    this.handleToLocation();
  },
  watch: {
    update_ship(ship) {
      if (ship) {
        this.updateShip();
        this.update_ship = false;
      }
    },
  },
});
